export const Role = {
  DEBTOR: "debtor",
  OPERATOR: "operator",
  SUPERUSER: "superuser",
  SERVICE: "service",
};

export const AuthDataType = {
  DATE: "DATE",
  TEXT: "TEXT",
};

export const AuthType = {
  CLAIM_FIELD: "CLAIM_FIELD",
  OTTO_OAUTH: "OTTO_OAUTH",
  BIRTHDAY: "BIRTHDAY",
  OAUTH: "OAUTH",
};

export const OAuthVendor = {
  OTTO: "OTTO",
};

export const DataKey = {
  ERROR: "error",
  AUTH_DATA: "authData",

  CLAIM: "claim",
  MERCHANT: "merchant",

  PAYMENT_MODALITIES: "paymentModalities",
  REQUESTED_MODALITIES: "requestedModalities",

  INSTALLMENT_PLAN_OPTIONS: "installmentPlanOptions",
  INSTALLMENT_PLAN: "installmentPlan",

  DISPUTE_OPTIONS: "disputeOptions",
  DISPUTE_REASON: "disputeReason",
  COLLECTED_CUSTOMER_SCORES: "collectedCustomerScores",

  PAYMENT_REQUEST: "paymentRequest",
  PAYMENT_PROVIDERS: "paymentProviders",
  PAYMENT_SETTINGS: "paymentSettings",
  PAYABLE_STATUS: "payableStatus",

  PLACEHOLDERS: "placeholders",
  PLACEHOLDERS_MERCHANT: "placeholders_merchant",
  ATTACHMENTS: "attachments",
  CONTACT_CONFIGURATIONS: "contactConfigurations",
  CONTACTS: "contacts",
};

export const Theme = {
  DUNNING: "dunning",
  EPRIMO: "eprimo",
  OTTO: "otto",
};

export enum AttachmentType {
  CONTRACT = "contract",
  INVOICE = "invoice",
  MESSAGE = "message",
  PROOF = "proof",
  RECEIPT = "receipt",
  TOS = "terms of service",
  OTHERS = "others",
}

export const DisputeOptionType = {
  REPLY: "REPLY",
  SIMPLE: "SIMPLE",
  FREETEXT: "FREETEXT",
};

export enum PageType {
  CLAIM = "CLAIM",
  PAYMENT = "PAYMENT",
  CONTACT = "CONTACT",
}

export enum UseCase {
  AUTHENTICATION = "authentication",
  PASSCODE = "passcode",
  PROGRESS = "progress",
  PROGRESS_EMAIL = "progress-email",
  PROGRESS_MOBILE = "progress-mobile",
  DISPUTE_RESOLVED = "dispute-resolved",
  SUCCESS = "success",
  FAILURE = "failure",
  ERROR = "error",
}

export enum CustomerScoreTypes {
  NPS = "NPS",
  CES = "CES",
  CSAT = "CSAT",
  GENERAL = "GENERAL",
}

export enum DocumentTypes {
  BANK_TRANSFER_DETAILS = "BANK_TRANSFER_DETAILS",
  INSTALLMENT_PLAN_OVERVIEW = "INSTALLMENT_PLAN_OVERVIEW",
}

export enum PaymentModalityType {
  PAYMENT_DEFERMENT = "PAYMENT_DEFERMENT",
  PAYMENT_PLAN = "PAYMENT_PLAN",
}

export enum DisputeReasonKeys {
  ORDER_RETURNED = "ORDER_RETURNED",
  ALREADY_PAID = "ALREADY_PAID",
  MISSING_DOCUMENTS = "MISSING_DOCUMENTS",
  OTHER = "OTHER",
}

export enum CustomerScoreCategory {
  DETRACTOR = "detractor",
  PROMOTER = "promoter",
  PASSIVE = "passive",
}

/** Possible status for a given payment request */
export enum PaymentMethod {
  Barzahlen = "BARZAHLEN",
  CreditCard = "CREDIT_CARD",
  DirectDebit = "DIRECT_DEBIT",
  DirectTransfer = "DIRECT_TRANSFER",
  Ideal = "IDEAL",
  ManualTransfer = "MANUAL_TRANSFER",
  Paypal = "PAYPAL",
  Sofort = "SOFORT",
  Trustly = "TRUSTLY",
}

export enum ProviderName {
  Barzahlen = "barzahlen",
  BetterPayment = "betterPayment",
  CollectDebit = "collectDebit",
  Computop = "computop",
  FinTechSystems = "finTechSystems",
  GoCardless = "goCardless",
  PayOne = "payOne",
  Paypal = "paypal",
  SepaTransfer = "sepaTransfer",
  Sofort = "sofort",
  Trustly = "trustly",
  VrPayment = "vrPayment",
}

export enum DisputeOptionTranslationType {
  Description = "DESCRIPTION",
  FirstPerson = "FIRST_PERSON",
  HintText = "HINT_TEXT",
  ThirdPerson = "THIRD_PERSON",
}

export enum RequestState {
  Approved = "APPROVED",
  Rejected = "REJECTED",
  Requested = "REQUESTED",
}

export enum InstallmentPlanStatus {
  Active = "ACTIVE",
  Completed = "COMPLETED",
  Invalidated = "INVALIDATED",
}
export enum DisputeState {
  Created = "CREATED",
  Invalidated = "INVALIDATED",
  Overruled = "OVERRULED",
  Resolved = "RESOLVED",
}

export enum AuthenticationType {
  CLAIM_FIELD = "CLAIM_FIELD",
  OTTO_OAUTH = "OTTO_OAUTH",
  BIRTHDAY = "BIRTHDAY",
  OAUTH = "OAUTH",
}

export enum ContactType {
  EMAIL = "EMAIL",
  MOBILE_NUMBER = "MOBILE_NUMBER",
  POSTAL_ADDRESS = "POSTAL_ADDRESS",
}

export enum ContactPageSetting {
  CONSENT_1 = "CONSENT_1",
  CONSENT_2 = "CONSENT_2",
}

export enum ContactState {
  VERIFIED = "VERIFIED",
  UNVERIFIED = "UNVERIFIED",
  VERIFICATION_EXPIRED = "VERIFICATION_EXPIRED",
  INVALID = "INVALID",
}
